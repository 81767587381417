<template>
  <div>
    <v-card>
      <v-card-title>SOLICITUD PARA COMPRA</v-card-title>
      <v-card-text>
        <!-- <botonera 
                    :botones="botones" 
                    alinear="right" 
                    @crear="agregarSolicitud"
                >
                </botonera> -->
        <!-- <v-text-field
                    label="Nombre Wellboat"
                    placeholder="Nombre Wellboat" 
                    v-model="wellboat_filtred"
                    style="max-width:180px"
                /> -->
        <div class="d-flex justify-space-between">
          <v-select
            v-model="wellboat_id"
            :items="wellboats_filtred"
            item-text="nombre"
            item-value="id"
            label="Wellboat"
            color="blue darken-3"
            persistent-hint
            :reduce="(item) => item.id"
            style="max-width:180px"
            @change="loadSolicitudes()"
          />

          <v-btn color="blue" dark @click="volver()">
            Volver
          </v-btn>
        </div>

        <v-alert
          border="left"
          outlined
          text
          :type="alert.tipo"
          v-if="alert.mostrar"
        >
          <v-row>
            <v-col>
              <h4>{{ alert.mensaje }}</h4>
            </v-col>
            <v-col class="text-right">
              <v-btn
                :color="alert.color_boton"
                elevation="2"
                small
                dark
                @click="cerrar_alert"
                >Cerrar</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="alert.errores.length > 0">
            <v-col>
              <ul>
                <li v-for="e in alert.errores" :key="e">{{ e }}</li>
              </ul>
            </v-col>
          </v-row>
        </v-alert>
      </v-card-text>
    </v-card>
    <div class="card">
      <div class="card-header card-header-primary card-header-icon">
        <div :class="`card-icon ${color_icono}`">
          <i class="material-icons" v-show="icono">{{ icono }}</i>
        </div>
        <h4 class="card-title">
          <div class="row">
            <div class="col-md-6">
              {{ titulo }}
            </div>
          </div>
        </h4>
      </div>
      <div class="card-body">
        <spinner v-if="spinner"></spinner>
        <v-simple-table :height="table_height">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center" :class="user.roles_ids.includes(8) ? 'pt-3 pb-1': ''">
                  SELECCIONE
                  <v-checkbox v-if="user.roles_ids.includes(8)" @change="seleccionarTodo" :value="solicitudes_seleccionadas" color="white" :hide-details="true" class="d-block my-0 mx-auto" :style="{width: 'max-content'}"></v-checkbox>
                </th>
                <th class="text-center">WELLBOAT</th>
                <th class="text-center">FOLIO</th>
                <th class="text-center">FECHA SOLICITUD</th>
                <th class="text-center">ENCARGADO ÁREA</th>
                <th class="text-center">ÁREA</th>
                <th class="text-center">CATEGORÍA</th>
                <th class="text-center">ITEM</th>
                <th class="text-center">DESCRIPCIÓN</th>
                <th class="text-center">CANTIDAD SOLICITADA</th>
                <th class="text-center">ARCHIVO ADJUNTO</th>
                <th class="text-center">CANTIDAD APROBADA</th>
                <th class="text-center">CANTIDAD AUTORIZADA</th>
                <th class="text-center">ESTADO</th>
                <th class="text-center acciones-column2">ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in solicitudes"
                :key="index"
                class="text-center"
              >
                <td class="pl-10">
                  <template>
                    <v-container class="px-0" fluid>
                      <v-checkbox @click.native.capture.stop="seleccionarItem(item)" :value="item.selected"></v-checkbox>
                    </v-container>
                  </template>
                </td>
                <td>
                  {{ item.wellboat }}
                </td>
                <td>
                  {{ `${item.centro_costo}-${item.folio}` }}
                </td>
                <td>
                  {{ item.fecha_solicitud }}
                </td>
                <td>
                  <!-- <v-select v-model="item.encargado_id" 
                                        :items="encargadosFilter(item)"
                                        item-text="name" item-value="id"
                                        label="Seleccione Encargado" color="blue darken-3" persistent-hint
                                        :reduce="item => item.id" style="max-width:150px"
                                        @change="updateEncatrgado(index)"
                                        disabled
                                    /> -->
                  {{ item.nombre_encargado }}
                </td>
                <td>
                  {{ item.area }}
                </td>
                <td>
                  {{ item.categoria }}
                </td>
                <td>
                  {{ item.material_codigo }}
                </td>
                <td>
                  {{ item.descripcion }}
                </td>
                <td>
                  {{ item.cantidad | formatear_miles}}
                </td>
                <td>
                  <a
                    @click="downloadFile(item.archivo)"
                    target="_blank"
                    class="ma-2"
                  >
                    <v-icon
                      large
                      v-if="
                        item && item.archivo && item.archivo.includes('.pdf')
                      "
                      style="color:#ef233c"
                    >
                      mdi-file-pdf-box
                    </v-icon>
                    <v-icon
                      large
                      v-else-if="item.archivo != null"
                      style="color:#52b788"
                    >
                      mdi-file-image
                    </v-icon>
                  </a>
                </td>
                <td>
                  {{ item.revisor_cantidad_aprob | formatear_miles}}
                </td>
                <td>
                  {{ item.aprobador_cantidad_aprob | formatear_miles}}
                </td>
                <td>
                  {{ item.estado }}
                </td>
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <a
                        class="ma-2"
                        @click="rechazarSolicitud(index)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon large color="red">
                          mdi-alpha-x-circle
                        </v-icon>
                      </a>
                    </template>
                    <span>Rechazar Solicitud</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div>
          <h5>Plazo en que se refiere</h5>
          <input type="radio" name="plazo" id="urgente" value="urgente" v-model="plazo" style="margin-right: 5px;">
          <label for="urgente" style="margin-right: 2em;color: #3c3c3c;">Urgente</label>
          <input type="radio" name="plazo" id="normal" value="normal" v-model="plazo" style="margin-right: 5px;">
          <label for="normal" style="margin-right: 2em; color: #3c3c3c;">Normal</label>
          <input type="radio" name="plazo" id="no_prioritario" value="no_prioritario" v-model="plazo" style="margin-right: 5px;">
          <label for="no_prioritario" style="margin-right: 2em;color: #3c3c3c;">No Prioritario</label>
        </div>
      </div>
      <!-- <v-btn
            v-if="filterSolicitudes.length>0"
            style="width:200px;"
            class="ma-2 ml-auto mr-5 mb-5"
            :loading="loading2"
            :disabled="loading2"
            color="success"
            @click="enviarSolicitudes()"
            >
            Enviar solicitud
            <v-icon
                large
                right
                dark
            >
                mdi-file-send
            </v-icon>
            <template v-slot:loader>
                <span>Loading...</span>
            </template>
            </v-btn> -->
    </div>

    <v-alert v-if="empty_data" border="right" color="blue-grey" dark>
      No hay solicitudes en revisión...
    </v-alert>
    <v-snackbar v-model="snackbar" :timeout="5000" :color="color">
      {{ validation_message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

    <div class="card">
      <div class="card-header card-header-blue">
        <h4 class="card-title">
          <div class="row">
            <div class="col-md-6">
              VISTA PREVIA ORDEN DE COMPRA
            </div>
          </div>
        </h4>
      </div>
      <div class="card-body">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr class="card-header-blue">
                <th class="text-center text-white">ITEM</th>
                <th class="text-center text-white">WELLBOAT</th>
                <th class="text-center text-white">DESCRIPCIÓN</th>
                <th class="text-center text-white">CANTIDAD</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in lista_previa"
                :key="index"
                class="text-center"
              >
                <td class="text-left">{{ item.material }}</td>
                <td>{{ item.wellboat }}</td>
                <td>{{ item.descripcion }}</td>
                <td>{{ item.aprobador_cantidad_aprob | formatear_miles}}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="row">
          <div class="col-12 d-flex justify-content-end mt-6">
            <v-btn
              style="width:200px;"
              class="ma-2 ml-auto mr-5 mb-5"
              :disabled="desactivar_boton"
              color="success"
              @click="enviarSolicitudes()"
            >
              Emitir solicitud
              <v-icon large right dark>
                mdi-file-send
              </v-icon>
              <template v-slot:loader>
                <span>Loading...</span>
              </template>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import Swal from 'sweetalert2';
export default {
  data: () => ({
    table_height: 550,
    spinner: false,
    titulo: "SELECCIONE LÍNEA(S) DE EMISIÓN SOLICITUD PARA COMPRA",
    color_icono: "azul",
    icono: "list",
    data_header: null,
    activePicker: null,
    solicitudes: [],
    encargados: [],
    items: [],
    select_categoria: "",
    validation_message: "",
    color: "green",
    snackbar: false,
    empty_data: false,
    data: [],
    wellboats_filtred: [],
    wellboats: "",
    wellboat_id: null,
    alert: {
      mostrar: false,
      tipo: "",
      color_boton: "",
      mensaje: "",
      errores: [],
    },
    plazo:null,
  }),
  mounted() {
    this.loadSolicitudes();
    // this.loadEncargados();
    this.loadWellboats();
  },
  computed: {
    ...mapState([
      "loading",
      "base_url",
      "headers",
      "headers_file",
      "wellboat",
      "user",
      "storage_url",
    ]),
    // filterSolicitudes: (data) => {
    //     const wellboatFiltredUpper = data.wellboat_filtred.toUpperCase();
    //     return wellboatFiltredUpper !== ''
    //         ? data.solicitudes.filter(s=>s.wellboat!=null).filter(s =>
    //             s.wellboat.toUpperCase().includes(wellboatFiltredUpper)
    //         )
    //         : data.solicitudes;
    // },
    lista_previa() {
      return this.solicitudes.filter((item) => item.selected);
    },
    desactivar_boton() {
      return !this.solicitudes.filter((item) => item.selected).length > 0;
    },
    solicitudes_seleccionadas() {
      return this.solicitudes.every(solicitud => solicitud.selected);
    },
  },
  methods: {
    // encargadosFilter(item){
    //     let result = this.encargados.filter(en => en.areas_ids.includes(item.areas_id))
    //     return result
    // },
    downloadFile(archivo) {
      const link = `${this.storage_url}solicitudes/${archivo}`;
      console.log(link);
      window.open(link);
    },
    cerrar_alert() {
      this.alert.mostrar = false;
      this.alert.tipo = "";
      this.alert.color_boton = "";
      this.alert.mensaje = "";
      this.alert.errores = [];
    },
    async loadSolicitudes() {
      this.spinner = true;
      this.mostrarLoading("Cargando listado de solicitudes...");
      let url = `${this.base_url}solicitudes/estado_solicitud/${4}`;
      let data = {
        roles: this.user.roles_ids,
        wellboat_id: this.wellboat_id,
        users_id: this.user.id,
        solicitudes_compras_id: true,
      };
      await this.axios
        .post(url, data, this.headers)
        .then((response) => {
          //this.solicitudes = response.data.data.filter(d=>this.user.areas_ids.includes(d.areas_id));
          this.solicitudes = response.data.data;
          this.solicitudes.forEach((s,idx) => {
            this.$set(this.solicitudes[idx],'selected',false);
          })
          response.data.data.length === 0 && (this.empty_data = true);
          this.ocultarLoading();
        })
        .catch((error) => {
          console.log(error);
        });
      this.spinner = false;
    },
    // async loadEncargados() {
    //     //let url = `${this.base_url}usuarios/encargados`;
    //     let url = `${this.base_url}listar/encargados`;
    //     let data={
    //         //areasIds: this.user.areas_ids,
    //         //roles:this.user.roles_ids,
    //         users_id:this.user.id
    //     };
    //     await this.axios.post(url, data, this.headers).then((response) => {
    //         this.encargados = response.data;
    //         this.encargados_filtred = [...[{ id: "", name: 'Todos' }],...response.data];
    //         this.ocultarLoading()
    //     }).catch((error) => {
    //         console.log(error);
    //     });
    // },
    async loadWellboats() {
      let url = `${this.base_url}wellboats`;
      // let data={
      //     //areasIds: this.user.areas_ids,
      //     //roles:this.user.roles_ids,
      //     users_id:this.user.id
      // };
      await this.axios
        .get(url, this.headers)
        .then((response) => {
          this.wellboats = response.data;
          this.wellboats_filtred = response.data.filter(wellboat => wellboat.mostrar);
          this.ocultarLoading();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    volver() {
      this.$router.push("solicitud_compras");
    },
    async enviarSolicitudes() {
      this.spinner = true;
      let url = `${this.base_url}items/0`;
      let data = {
        itemsIds: [
          ...[],
          ...this.solicitudes
            .filter((s) => s.selected == true)
            .map((s) => s.id),
        ],
        solicitudes: this.solicitudes.filter((s) => s.selected == true),
        estados_solicitudes_id: 4,
        estados_solicitudes: 7,
        users_id: this.user.id,
        plazo:this.plazo,
      };

      await Swal.fire({
        title: "¿Está seguro de que desea emitir esta solicitud?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#329EF4",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, emitir"
      }).then(async(result) => {
        if (result.value) {
          await this.axios
          .put(url, data, this.headers)
          .then((response) => {
            response.status === 200 &&
              new Promise(() => {
                this.alert.mostrar = true;
                this.alert.tipo = "success";
                this.alert.color_boton = "green";
                this.alert.mensaje = response.data.message;
                /* this.alert.errores = [...[], ...this.solicitudes.filter(s => s.selected == true).map(s => {
                          let wellboat = s.wellboat != null ? `de Wellboat ${s.wellboat}` : ''
                          return `Solicitud ${wellboat} con folio ${s.folio}`;
                      })]; */
                if (
                  response.data.url_pdf != undefined &&
                  response.data.url_pdf != ""
                ) {
                  window.open(
                    `${this.storage_url}materiales/compras/${response.data.url_pdf}`
                  );
                }
                this.solicitudes
                  .filter((s) => s.selected == true)
                  .map((s) => {
                    this.solicitudes = this.solicitudes.filter(
                      (s1) => s1.id != s.id
                    );
                  });
              });
            this.ocultarLoading();
          })
          .catch((error) => {
            this.alert.mostrar = true;
            this.alert.tipo = "error";
            this.alert.color_boton = "red";
            this.alert.mensaje = error.response.data.message;
            this.alert.errores = error.response.data.errors;
          });
        }
      });
      
      this.spinner = false;
    },
    showSnackBar(message = "", error = false) {
      if (error) {
        this.color = "red";
        this.validation_message = message || "Ha ocurrido un error";
      } else {
        this.color = "green";
        this.validation_message = message || "Ejecutado exitosamente!";
      }
      this.snackbar = true;
    },
    async rechazarSolicitud(index) {
      this.spinner = true;
      let url = `${this.base_url}items/${this.solicitudes[index].id}`;
      let data = {
        estados_solicitudes_id: 6,
      };

      this.solicitudes[index].id === null
        ? this.solicitudes.splice(index, 1)
        : this.$confirm(
            "¿Estás seguro de que deseas rechazar esta solicitud?"
          ).then(async (res) => {
            res &&
              new Promise(() => {
                this.axios
                  .put(url, data, this.headers)
                  .then((response) => {
                    response.status === 200 &&
                      new Promise(() => {
                        this.alert.mostrar = true;
                        this.alert.tipo = "success";
                        this.alert.color_boton = "green";
                        let wellboat = this.solicitudes[index].wellboat
                          ? `wellboat ${this.solicitudes[index].wellboat}`
                          : "";
                        this.alert.mensaje = `Se ha rechazado la solicitud ${wellboat} con folio ${this.solicitudes[index].folio}`;
                        this.solicitudes.splice(index, 1);
                      });
                    this.ocultarLoading();
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              });
          });
      this.spinner = false;
    },
    selectCategoria(value) {
      this.select_categoria = value;
    },
    selectItem() {
      this.select_categoria = "";
    },
    retornar_materiales(categorias_id) {
      if (categorias_id) {
        return this.items.filter((m) => m.categorias_id == categorias_id);
      }
      return [];
    },
    seleccionarTodo(value) {
      let selected = -1;
      this.solicitudes = this.solicitudes.map(solicitud => {
        selected++;
        return {
          ...solicitud,
          selected: selected >= 10 ? false : value,
        }
      });
    },
    seleccionarItem(item) {
      let selected = 0;
      let maxItemsSelected = false;
      for (const solicitud of this.solicitudes) {
        if (solicitud.selected) selected++;
        if (selected >= 10) {
          maxItemsSelected = true;
          break;
        }
      }
      if (maxItemsSelected && !item.selected) {
        this.showSnackBar("Se puede seleccionar como máximo 10 ítems", true);
      }
      item.selected = maxItemsSelected ? false : !item.selected;
    },
    ...mapMutations(["mostrarLoading", "ocultarLoading"]),
  },
};
</script>
<style scoped>
th {
  background-color: #355f94 !important;
  color: #fff !important;
  border: 1px solid #fff;
}

.acciones-column2 {
  width: 150px;
}
</style>
